import $ from 'jquery';
import 'lightgallery';
import 'lg-fullscreen';
import 'lg-pager';
import 'lg-thumbnail';
import 'lg-video';
import 'lg-zoom';
import 'lightgallery/src/sass/lightgallery.scss';
import { fromUnixTime, format } from 'date-fns';

$(() => {
  const photoCount = $('#timeline .photo-item').length + 1;
  $('#timeline').css('counter-reset', `photo-count ${photoCount}`);

  $('.date').each((_, el) => {
    const $this = $(el);
    const timestamp = $this.data('timestamp');

    $this.text(format(fromUnixTime(timestamp), 'PP'));
  });

  $('.photo-grid .photo-item').click(function (ev) {
    ev?.preventDefault();
    const $this = $(this);
    const $grid = $this.closest('.photo-grid');
    const index = $grid.find('.photo-item').index($this);
    const images = [];

    $grid.find('.photo-item').each((_, image) => {
      const $image = $(image);
      const videoId = $image.data('video-id');
      images.push(
        videoId
          ? {
              thumb: $image.data('thumb'),
              poster: $image.data('thumb'),
              html: ['#video', videoId].join('-'),
            }
          : {
              thumb: $image.data('thumb'),
              src: $image.data('full'),
            },
      );
    });

    const lightGallery = $grid;
    lightGallery.lightGallery({
      dynamic: true,
      dynamicEl: images,
      index,
    });

    lightGallery.on('onCloseAfter.lg', () => {
      lightGallery.data('lightGallery').destroy(true);
    });
  });
});

$('.sort-button').on('click', function () {
  $('#timeline').toggleClass('reverse');
  $(this).children('span').toggleClass('active');
});

// Let's lazyload the background images
function BackgroundNode({ node, loadedClassName }) {
  const src = node.getAttribute('data-background-image-url');
  const show = (onComplete) => {
    window.requestAnimationFrame(() => {
      // eslint-disable-next-line no-param-reassign
      node.style.backgroundImage = `url(${src})`;
      node.classList.add(loadedClassName);
      onComplete();
    });
  };

  return {
    node,

    // onComplete is called after the image is done loading.
    load: (onComplete) => {
      const img = new Image();
      img.onload = show(onComplete);
      img.src = src;
    },
  };
}

const defaultOptions = {
  selector: '[data-background-image-url]',
  loadedClassName: 'loaded',
};

function BackgroundLazyLoader({ selector, loadedClassName } = defaultOptions) {
  let nodes = [].slice
    .apply(document.querySelectorAll(selector))
    .map((node) => new BackgroundNode({ node, loadedClassName }));

  const callback = (entries, observer) => {
    entries.forEach(({ target, isIntersecting }) => {
      if (!isIntersecting) {
        return;
      }

      const obj = nodes.find((it) => it.node.isSameNode(target));

      if (obj) {
        obj.load(() => {
          // Unobserve the node:
          observer.unobserve(target);
          // Remove this node from our list:
          nodes = nodes.filter((n) => !n.node.isSameNode(target));

          // If there are no remaining unloaded nodes,
          // disconnect the observer since we don't need it anymore.
          if (!nodes.length) {
            observer.disconnect();
          }
        });
      }
    });
  };

  const observer = new window.IntersectionObserver(callback);
  nodes.forEach((node) => observer.observe(node.node));
}

BackgroundLazyLoader();
